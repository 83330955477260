<template>
  <div>
    <div class="inner_head">
      <img class="head_img" src="../../statics/images/company/detailCompany.png" alt="">
    </div>
    <div class="company_list">
      <ul>
        <li v-for="(item,index) in companyList" :key="index">
          <img :src="baseUrl + item.iconUrl" alt="">
          <div class="info">
            <p class="title">{{ item.title }}</p>
            <p class="detail">{{ item.remark }}</p>
            <a class="more" @click="lookMore(item)">了解更多</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getMainContent } from '@/plugin/api'
export default {
  data() {
    return {
      baseUrl: '/website',
      companyList: []
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getMainContent,
    getInfo() {
      const { query } = this.$route
      const urlType = query.fUrlType ? query.fUrlType : query.urlType
      const url = query.fUrl ? query.fUrl : query.url
      this.getMainContent({ tenantId: 'xljr', url, urlType }).then(res => {
        const arr = res.data.data.contentMainList[0].contentItemTreeList
        this.companyList = arr
      })
    },
    lookMore(item) {
      this.$router.push({ path: '/xljrDetailCompany', query: { url: item.url, urlType: item.urlType }})
    }
  }
}
</script>
<style lang="scss" scoped>
.company_list{
  width: 1200px;
  margin: 0 auto;
  ul{
    li{
      position: relative;
      text-align: right;
      padding: 100px 0;
      &:nth-child(2n){
        text-align: left;
        .info{
          left: unset;
          right: 0;
        }
      }
      img{
        width: 870px;
      }
      .info{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 350px;
        padding: 55px;
        display: flex;
        flex-direction: column;
        text-align: left;
        background-color: #fff;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        .title{
          color: #333;
          font-size: 32px;
          margin-bottom: 20px;
        }
        .detail{
          color: #666;
          font-size: 14px;
          margin-bottom: 45px;
        }
        .more{
          cursor: pointer;
          background-color: #015FC9;
          color: #FFF;
          font-size: 14px;
          display: block;
          width: 130px;
          text-align: center;
          height: 45px;
          line-height: 45px;
        }
      }
    }
  }
}
</style>
